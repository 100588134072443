<template>
	<div class="equipamientoFormHeader puiformheader">
		<v-layout row wrap>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.equipamiento.tipotamanyo')" :value="getTipoTamanyoValue"></pui-field>
			</v-flex>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.equipamiento.matricula')" :value="getMatriculaValue"></pui-field>
			</v-flex>
			<v-flex xs12 md3>
				<pui-field :label="$t('header.equipamiento.numdeclaracion')" :value="getNumdeclaracionValue"></pui-field>
			</v-flex>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.equipamiento.escala')" :value="getEscalaValue"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'equipamiento-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'equipamiento'
		};
	},
	computed: {
		getTipoTamanyoValue() {
			return this.model && this.model.codtipotamanyo ? this.model.codtipotamanyo : '-';
		},
		getMatriculaValue() {
			return this.model && this.model.matricula ? this.model.matricula : '-';
		},
		getNumdeclaracionValue() {
			return this.model && this.model.numdeclaracion ? this.model.numdeclaracion : '-';
		},
		getEscalaValue() {
			return this.model && this.model.escala ? this.model.escala : '-';
		}
	}
};
</script>
